import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8ea8314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoginView = _resolveComponent("LoginView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.user.isLoggedin)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Menu, { onLogout: _ctx.logout }, null, 8, ["onLogout"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view, {
              api: (_ctx.api as DefaultApi)
            }, null, 8, ["api"])
          ])
        ], 64))
      : (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoginView, {
            key: 1,
            api: (_ctx.api as DefaultApi),
            onLogin: _ctx.login
          }, null, 8, ["api", "onLogin"]))
        : _createCommentVNode("", true),
    _createVNode(_component_notifications, { position: "bottom right" })
  ], 64))
}