/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuthSessionR } from './AuthSessionR';
import {
    AuthSessionRFromJSON,
    AuthSessionRFromJSONTyped,
    AuthSessionRToJSON,
} from './AuthSessionR';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListAuthSession200Response
 */
export interface ListAuthSession200Response {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListAuthSession200Response
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<AuthSessionR>}
     * @memberof ListAuthSession200Response
     */
    objects: Array<AuthSessionR>;
}

/**
 * Check if a given object implements the ListAuthSession200Response interface.
 */
export function instanceOfListAuthSession200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "objects" in value;

    return isInstance;
}

export function ListAuthSession200ResponseFromJSON(json: any): ListAuthSession200Response {
    return ListAuthSession200ResponseFromJSONTyped(json, false);
}

export function ListAuthSession200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAuthSession200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'objects': ((json['objects'] as Array<any>).map(AuthSessionRFromJSON)),
    };
}

export function ListAuthSession200ResponseToJSON(value?: ListAuthSession200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'objects': ((value.objects as Array<any>).map(AuthSessionRToJSON)),
    };
}

