/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Auth,
  AuthW,
  ListAuth200Response,
  ListAuthSession200Response,
  ListSession200Response,
  ListToken200Response,
  ListTokenRoles200Response,
  Session,
  SessionW,
  Token,
  TokenRoles,
  TokenRolesW,
  TokenW,
} from '../models';
import {
    AuthFromJSON,
    AuthToJSON,
    AuthWFromJSON,
    AuthWToJSON,
    ListAuth200ResponseFromJSON,
    ListAuth200ResponseToJSON,
    ListAuthSession200ResponseFromJSON,
    ListAuthSession200ResponseToJSON,
    ListSession200ResponseFromJSON,
    ListSession200ResponseToJSON,
    ListToken200ResponseFromJSON,
    ListToken200ResponseToJSON,
    ListTokenRoles200ResponseFromJSON,
    ListTokenRoles200ResponseToJSON,
    SessionFromJSON,
    SessionToJSON,
    SessionWFromJSON,
    SessionWToJSON,
    TokenFromJSON,
    TokenToJSON,
    TokenRolesFromJSON,
    TokenRolesToJSON,
    TokenRolesWFromJSON,
    TokenRolesWToJSON,
    TokenWFromJSON,
    TokenWToJSON,
} from '../models';

export interface CreateAuthRequest {
    authW: AuthW;
}

export interface CreateAuthSessionRequest {
    authW: AuthW;
}

export interface CreateSessionRequest {
    sessionW: SessionW;
}

export interface CreateTokenRequest {
    tokenW: TokenW;
}

export interface CreateTokenRolesRequest {
    tokenRolesW: TokenRolesW;
}

export interface GetAuthRequest {
    uuid: string;
}

export interface GetSessionRequest {
    id: number;
}

export interface GetTokenRequest {
    id: number;
}

export interface GetTokenRolesRequest {
    name: string;
}

export interface ListAuthRequest {
    uuid?: string;
    username?: string;
}

export interface ListSessionRequest {
    user?: string;
    comments?: string;
    createdTs?: Date;
    start?: Date;
    end?: Date;
}

export interface ListTokenRequest {
    session?: string;
    comments?: string;
    createdTs?: Date;
    validAfter?: Date;
    validBefore?: Date | null;
}

export interface PutSessionRequest {
    id: number;
    sessionW: SessionW;
}

export interface PutTokenRequest {
    id: number;
    tokenW: TokenW;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create Auth
     */
    async createAuthRaw(requestParameters: CreateAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authW === null || requestParameters.authW === undefined) {
            throw new runtime.RequiredError('authW','Required parameter requestParameters.authW was null or undefined when calling createAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthWToJSON(requestParameters.authW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Auth
     */
    async createAuth(requestParameters: CreateAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Create AuthSession
     */
    async createAuthSessionRaw(requestParameters: CreateAuthSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authW === null || requestParameters.authW === undefined) {
            throw new runtime.RequiredError('authW','Required parameter requestParameters.authW was null or undefined when calling createAuthSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/authsession/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthWToJSON(requestParameters.authW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create AuthSession
     */
    async createAuthSession(requestParameters: CreateAuthSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAuthSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Create Session
     */
    async createSessionRaw(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionW === null || requestParameters.sessionW === undefined) {
            throw new runtime.RequiredError('sessionW','Required parameter requestParameters.sessionW was null or undefined when calling createSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/session/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SessionWToJSON(requestParameters.sessionW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Session
     */
    async createSession(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Create Token
     */
    async createTokenRaw(requestParameters: CreateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tokenW === null || requestParameters.tokenW === undefined) {
            throw new runtime.RequiredError('tokenW','Required parameter requestParameters.tokenW was null or undefined when calling createToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenWToJSON(requestParameters.tokenW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Token
     */
    async createToken(requestParameters: CreateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Create TokenRoles
     */
    async createTokenRolesRaw(requestParameters: CreateTokenRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tokenRolesW === null || requestParameters.tokenRolesW === undefined) {
            throw new runtime.RequiredError('tokenRolesW','Required parameter requestParameters.tokenRolesW was null or undefined when calling createTokenRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/tokenroles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRolesWToJSON(requestParameters.tokenRolesW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create TokenRoles
     */
    async createTokenRoles(requestParameters: CreateTokenRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTokenRolesRaw(requestParameters, initOverrides);
    }

    /**
     * Get a single Auth by primary key
     */
    async getAuthRaw(requestParameters: GetAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Auth>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/auth/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthFromJSON(jsonValue));
    }

    /**
     * Get a single Auth by primary key
     */
    async getAuth(requestParameters: GetAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Auth> {
        const response = await this.getAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single Session by primary key
     */
    async getSessionRaw(requestParameters: GetSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/session/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Get a single Session by primary key
     */
    async getSession(requestParameters: GetSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.getSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single Token by primary key
     */
    async getTokenRaw(requestParameters: GetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Get a single Token by primary key
     */
    async getToken(requestParameters: GetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.getTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single TokenRoles by primary key
     */
    async getTokenRolesRaw(requestParameters: GetTokenRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRoles>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getTokenRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/tokenroles/{name}/`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRolesFromJSON(jsonValue));
    }

    /**
     * Get a single TokenRoles by primary key
     */
    async getTokenRoles(requestParameters: GetTokenRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRoles> {
        const response = await this.getTokenRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Auth with filtering
     */
    async listAuthRaw(requestParameters: ListAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAuth200Response>> {
        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/auth/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAuth200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Auth with filtering
     */
    async listAuth(requestParameters: ListAuthRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAuth200Response> {
        const response = await this.listAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of AuthSession with filtering
     */
    async listAuthSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAuthSession200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/authsession/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAuthSession200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of AuthSession with filtering
     */
    async listAuthSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAuthSession200Response> {
        const response = await this.listAuthSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of Session with filtering
     */
    async listSessionRaw(requestParameters: ListSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSession200Response>> {
        const queryParameters: any = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.comments !== undefined) {
            queryParameters['comments'] = requestParameters.comments;
        }

        if (requestParameters.createdTs !== undefined) {
            queryParameters['created_ts'] = (requestParameters.createdTs as any).toISOString();
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/session/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSession200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Session with filtering
     */
    async listSession(requestParameters: ListSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSession200Response> {
        const response = await this.listSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Token with filtering
     */
    async listTokenRaw(requestParameters: ListTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListToken200Response>> {
        const queryParameters: any = {};

        if (requestParameters.session !== undefined) {
            queryParameters['session'] = requestParameters.session;
        }

        if (requestParameters.comments !== undefined) {
            queryParameters['comments'] = requestParameters.comments;
        }

        if (requestParameters.createdTs !== undefined) {
            queryParameters['created_ts'] = (requestParameters.createdTs as any).toISOString();
        }

        if (requestParameters.validAfter !== undefined) {
            queryParameters['valid_after'] = (requestParameters.validAfter as any).toISOString();
        }

        if (requestParameters.validBefore !== undefined) {
            queryParameters['valid_before'] = (requestParameters.validBefore as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/token/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListToken200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Token with filtering
     */
    async listToken(requestParameters: ListTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListToken200Response> {
        const response = await this.listTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of TokenRoles with filtering
     */
    async listTokenRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTokenRoles200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v0/tokenroles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTokenRoles200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of TokenRoles with filtering
     */
    async listTokenRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTokenRoles200Response> {
        const response = await this.listTokenRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Overwrite a single Session by primary key
     */
    async putSessionRaw(requestParameters: PutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putSession.');
        }

        if (requestParameters.sessionW === null || requestParameters.sessionW === undefined) {
            throw new runtime.RequiredError('sessionW','Required parameter requestParameters.sessionW was null or undefined when calling putSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/session/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SessionWToJSON(requestParameters.sessionW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Overwrite a single Session by primary key
     */
    async putSession(requestParameters: PutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Overwrite a single Token by primary key
     */
    async putTokenRaw(requestParameters: PutTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putToken.');
        }

        if (requestParameters.tokenW === null || requestParameters.tokenW === undefined) {
            throw new runtime.RequiredError('tokenW','Required parameter requestParameters.tokenW was null or undefined when calling putToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v0/token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TokenWToJSON(requestParameters.tokenW),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Overwrite a single Token by primary key
     */
    async putToken(requestParameters: PutTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTokenRaw(requestParameters, initOverrides);
    }

}
