/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * NO_DESCRIPTION
     * @type {string}
     * @memberof Session
     */
    user: string;
    /**
     * NO_DESCRIPTION
     * @type {Date}
     * @memberof Session
     */
    createdTs: Date;
    /**
     * ID
     * @type {number}
     * @memberof Session
     */
    id: number;
    /**
     * comments
     * @type {string}
     * @memberof Session
     */
    comments: string;
    /**
     * start
     * @type {Date}
     * @memberof Session
     */
    start: Date;
    /**
     * end
     * @type {Date}
     * @memberof Session
     */
    end: Date;
}

/**
 * Check if a given object implements the Session interface.
 */
export function instanceOfSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "createdTs" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

    return isInstance;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': json['user'],
        'createdTs': (new Date(json['created_ts'])),
        'id': json['id'],
        'comments': json['comments'],
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'created_ts': (value.createdTs.toISOString()),
        'id': value.id,
        'comments': value.comments,
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
    };
}

