
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginView",
  data: () => {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      fetch("/api/v0/authsession/", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        }),
      }).then((resp) => {
        if (resp.status === 201) {
          this.$emit("login", true);
        }

        if (resp.status === 401) {
          this.$notify({
            type: "error",
            title: "The username or password is incorrect!",
            duration: 2500,
          });
        }
      });
    },
  },
});
