/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';
import type { TokenRoles } from './TokenRoles';
import {
    TokenRolesFromJSON,
    TokenRolesFromJSONTyped,
    TokenRolesToJSON,
} from './TokenRoles';

/**
 * 
 * @export
 * @interface ListTokenRoles200Response
 */
export interface ListTokenRoles200Response {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListTokenRoles200Response
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<TokenRoles>}
     * @memberof ListTokenRoles200Response
     */
    objects: Array<TokenRoles>;
}

/**
 * Check if a given object implements the ListTokenRoles200Response interface.
 */
export function instanceOfListTokenRoles200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "objects" in value;

    return isInstance;
}

export function ListTokenRoles200ResponseFromJSON(json: any): ListTokenRoles200Response {
    return ListTokenRoles200ResponseFromJSONTyped(json, false);
}

export function ListTokenRoles200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTokenRoles200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'objects': ((json['objects'] as Array<any>).map(TokenRolesFromJSON)),
    };
}

export function ListTokenRoles200ResponseToJSON(value?: ListTokenRoles200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'objects': ((value.objects as Array<any>).map(TokenRolesToJSON)),
    };
}

