/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRolesW
 */
export interface TokenRolesW {
    /**
     * description
     * @type {string}
     * @memberof TokenRolesW
     */
    description: string;
    /**
     * scopes
     * @type {string}
     * @memberof TokenRolesW
     */
    scopes: string;
}

/**
 * Check if a given object implements the TokenRolesW interface.
 */
export function instanceOfTokenRolesW(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "scopes" in value;

    return isInstance;
}

export function TokenRolesWFromJSON(json: any): TokenRolesW {
    return TokenRolesWFromJSONTyped(json, false);
}

export function TokenRolesWFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRolesW {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'scopes': json['scopes'],
    };
}

export function TokenRolesWToJSON(value?: TokenRolesW | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'scopes': value.scopes,
    };
}

