/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Auth } from './Auth';
import {
    AuthFromJSON,
    AuthFromJSONTyped,
    AuthToJSON,
} from './Auth';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListAuth200Response
 */
export interface ListAuth200Response {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListAuth200Response
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<Auth>}
     * @memberof ListAuth200Response
     */
    objects: Array<Auth>;
}

/**
 * Check if a given object implements the ListAuth200Response interface.
 */
export function instanceOfListAuth200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "objects" in value;

    return isInstance;
}

export function ListAuth200ResponseFromJSON(json: any): ListAuth200Response {
    return ListAuth200ResponseFromJSONTyped(json, false);
}

export function ListAuth200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAuth200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'objects': ((json['objects'] as Array<any>).map(AuthFromJSON)),
    };
}

export function ListAuth200ResponseToJSON(value?: ListAuth200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'objects': ((value.objects as Array<any>).map(AuthToJSON)),
    };
}

