export class User {
  private _isLoggedin: boolean;

  constructor(isLoggedin = false) {
    this._isLoggedin = isLoggedin;
  }

  public get isLoggedin() {
    return this._isLoggedin;
  }

  public set isLoggedin(isLoggedin: boolean) {
    this._isLoggedin = isLoggedin;
  }
}
