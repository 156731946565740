
import { defineComponent } from "vue";
import {
  Configuration,
  DefaultApi,
  FetchParams,
  RequestContext,
} from "@/openapi";

import Menu from "@/components/Menu.vue";
import LoginView from "@/views/LoginView.vue";

import { User } from "@/models/user";
import { RouterView } from "vue-router";

function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((cookie) => {
      return cookie.substring(0, nameLenPlus) === `${name}=`;
    })
    .map((cookie) => {
      return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0];
}

const csrf: (context: RequestContext) => Promise<void | FetchParams> = (
  context
) => {
  context.init.headers = new Headers(context.init.headers);
  context.init.headers.append("X-CSRFToken", getCookie("csrftoken"));

  return new Promise((resolve) => resolve(context));
};

const config = new Configuration({
  basePath: "",
  middleware: [
    {
      pre: csrf,
    },
  ],
  credentials: "include",
});

export default defineComponent({
  name: "App",
  components: { Menu, LoginView },
  data() {
    return {
      api: new DefaultApi(config),
      user: new User(),

      loading: true,
    };
  },
  methods: {
    login(isLoggedin: boolean): void {
      this.user.isLoggedin = isLoggedin;
    },
    logout() {
      this.user = new User();
    },
  },
  mounted() {
    fetch("/api/v0/authsession/", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res.objects.length) {
          this.user.isLoggedin = true;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
