import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const HomeView = () => import("@/views/HomeView.vue").then((m) => m.default);
const SessionView = () =>
  import("@/views/SessionView.vue").then((m) => m.default);
const TokenView = () => import("@/views/TokenView.vue").then((m) => m.default);
const MetricsView = () =>
  import("@/views/MetricsView.vue").then((m) => m.default);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/session",
    name: "session",
    component: SessionView,
  },
  {
    path: "/token",
    name: "token",
    component: TokenView,
  },
  {
    path: "/metrics",
    name: "Metrics",
    component: MetricsView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;
