
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuComponent",
  emits: ["logout"],
  props: {
    msg: String,
  },
  methods: {
    logout() {
      fetch("/api/v0/authsession/1", {
        method: "DELETE",
        credentials: "include",
      });

      this.$emit("logout");
    },
  },
});
